.site-footer {
    background-color: transparent; /* Footer background color */
    color: #6c757d; /* Text color */
    padding: 1rem 0; 
    text-align: center;
}

.footer-links a {
    color: #007bff; /* Link color */
    text-decoration: none;
    margin: 0 0.5rem;
}

.footer-links a:hover {
    text-decoration: underline;
}
